import { CssBaseline, ThemeProvider as MUIhemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { themeMui } from './theme-mui';

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = function ThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <MUIhemeProvider theme={themeMui}>
        <CssBaseline />
        {children}
      </MUIhemeProvider>
    </StyledEngineProvider>
  );
};
