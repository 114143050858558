import clsx from 'clsx';
import React from 'react';
import classes from './avatar.module.scss';

type TSmashedAvatar = {
  amount: number;
  disabled?: boolean;
  hasBorder?: boolean;
  avatarShape?: 'circle' | 'square';
  action?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const SmashedAvatar = ({ amount, disabled, hasBorder, avatarShape = 'circle', action }: TSmashedAvatar) => {
  return (
    <div
      ref={ref => {
        if (!ref) return;
        ref.onclick = event => {
          event.stopPropagation();
          // @ts-ignore
          action && action(event);
        };
      }}
      className={clsx(
        classes.smashedAvatar,
        classes.textPrimary,
        disabled && classes.disabled,
        hasBorder && classes.bordered,
        avatarShape === 'square' && classes.squared,
        action && classes.isClickable,
      )}
    >
      {String(amount).length < 3 ? `+${String(amount)}` : '99+'}
    </div>
  );
};
