import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

export type TSvgProps = {
  color: string;
  size: string;
};

export interface IIconProps {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  className?: string;
  hasSafebox?: boolean;
  size?: keyof typeof iconSizes;
  color?: keyof typeof iconColors;
  iconType?: keyof typeof iconTypes;
}

interface IIconWrapProps extends IIconProps {
  solidSvg?: ({ color, size }: TSvgProps) => JSX.Element;
  outlinedSvg: ({ color, size }: TSvgProps) => JSX.Element;
}

export const iconTypes = {
  outlined: 'outlined',
  solid: 'solid',
};
const defaultIconType = 'outlined';

// Размеры по гайдлайну: https://www.figma.com/file/F2iYq3EwAl9t0BoDbFzW2Z/Plarin-Icons?node-id=23%3A1386
export const iconSizes = {
  14: '14px',
  16: '16px',
  20: '20px', // common
  24: '24px', // default
  28: '28px',
  32: '32px',
  40: '40px',
  48: '48px',
  56: '56px',
};

export const defaultIconSize = 24;

// название ключа объекта - это размер svg внутри IconContainer
// значение ключа объекта - размер обёртки вокруг svg (на 16 px больше svg)
const sizesOfSafebox = {
  14: '',
  16: '32px', // svg 16px -> icon 32px
  20: '', // ----- нет по гайдлайну
  24: '40px', // svg 24px -> icon 40px
  28: '', // ----- нет по гайдлайну
  32: '48px', // svg 32px -> icon 48px
  40: '56px', // svg 40px -> icon 56px
  48: '', // ---- нет по гайдлайну
  56: '', // ----- нет по гайдлайну
};

// TODO: попробовать сделать через переменные с импортом из scss
// https://stackoverflow.com/a/63429103/17752264
export const iconColors = {
  primary60: '#214ECB',
  primary30Disable: '#779afc',
  main: '#626F84',
  secondary: '#2B313B',
  disabled: '#C4CAD4',
  blocked: '#626F84 ',
  white: '#fff',
  grey: '#A7B0BE',
  tertiary: '#8A96A8',
  /* ниже дополнительные цвета, не предусмотренные в icons kit, но используемые в макетах */
  status_success: '#7fc222',
  status_failed: '#ed5e66',
  status_error: '#D81823',
  status_warning: '#E48D0C',
};
const defaultIconColor = 'main';

/* Эта обёртка для svg даёт такие плюшки:
   1. Отображает иконку сразу в одном из стандартных размеров, плюс svg будет отцентровано внутри квадратного div (нужна правильно экспортированная svg с viewBox="0 0 24 24")
   2. Можно перекрасить иконку в один из допустимых по гайдлайну цветов
   3. Можно добавить сейфбокс, если он нужен
   4. Можно менять стиль картинки с outlined на solid
*/
export const IconWrapper = ({
  solidSvg: SolidSvg,
  outlinedSvg: OutlinedSvg,
  onClick,
  className,
  size = defaultIconSize,
  color = defaultIconColor,
  iconType = defaultIconType,
  hasSafebox,
}: IIconWrapProps) => {
  const Icon = () => {
    return (
      <div
        onClick={onClick}
        onMouseDown={e => e.preventDefault()}
        className={clsx(classes.icon, className)}
        style={{
          width: iconSizes[size],
          height: iconSizes[size],
        }}
      >
        {iconType === 'solid' && SolidSvg ? (
          <SolidSvg size={iconSizes[size]} color={iconColors[color]} />
        ) : (
          <OutlinedSvg size={iconSizes[size]} color={iconColors[color]} />
        )}
      </div>
    );
  };

  interface SafeBoxProps {
    children: React.ReactNode; // Define the children prop type
  }

  const SafeBox: React.FC<SafeBoxProps> = ({ children }) => {
    return (
      <div
        className={clsx(classes.icon, className)}
        style={{
          width: sizesOfSafebox[size],
          height: sizesOfSafebox[size],
        }}
      >
        {children}
      </div>
    );
  };

  return hasSafebox && sizesOfSafebox[size] ? <SafeBox>{Icon()}</SafeBox> : Icon();
};
