import clsx from 'clsx';
import React from 'react';
import classes from './avatar.module.scss';
import { AvatarsIconTypes, getConvertName, getConvertStr, getIcon, getLetters, getTextColorForAvatar } from './utils';

type TAvatar = {
  email?: string;
  name?: string;
  fname?: string;
  lname?: string;
  backgroundColor?: string;
  hasBorder?: boolean;
  shape?: 'circle' | 'square';
  avatarIconType?: AvatarsIconTypes;
  size?: 20 | 24 | 40;
  action?: () => void;
  classProps?: string;
};

export const Avatar = ({
  name,
  email,
  fname: propsFname,
  lname: propsLname,
  backgroundColor: propsBgColor,
  hasBorder,
  shape = 'circle',
  avatarIconType,
  size = 24,
  action,
  classProps,
}: TAvatar) => {
  const fname = propsFname && getConvertStr(propsFname);
  const lname = propsLname && getConvertStr(propsLname);
  const letters = name ? getConvertName(name, email) : getLetters(fname, lname, email);

  const textColor = propsBgColor && getTextColorForAvatar(propsBgColor);
  const colorsAreSpecified = propsBgColor && textColor;

  const styles: React.CSSProperties | undefined = colorsAreSpecified
    ? {
        background: `#${propsBgColor}`,
        color: `#${textColor}`,
      }
    : undefined;

  return (
    <div
      style={styles}
      className={clsx(
        classes.profileAvatar,
        hasBorder && classes.bordered,
        shape === 'square' && classes.squared,
        size === 20 && classes.size20,
        size === 40 && classes.size40,
        size === 40 && avatarIconType && classes.disabledBG,
        action && classes.isClickable,
        classProps,
        avatarIconType === 'folder' && classes.folder,
        (avatarIconType || !colorsAreSpecified) && classes.iconType,
      )}
      onClick={action}
    >
      {/* внутри аватарки либо иконка, либо буквы */}
      {avatarIconType ? getIcon(avatarIconType, size) : letters}
    </div>
  );
};
