import { SvgIcon } from '@mui/material';
import React from 'react';
import { BasicIcon } from '../_utils/type';

export const AgGreedTreeClosed = ({ className }: BasicIcon) => (
  <SvgIcon className={className} viewBox="0 0 32 32">
    <path
      fill="#C4CAD4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.061 16l-8.706 8.708-1.416-1.416 7.294-7.292-7.294-7.292 1.416-1.416c2.902 2.904 5.804 5.806 8.706 8.708z"
    ></path>
  </SvgIcon>
);
