import React from 'react';
import { ArchiveIcon, CloseIcon, FolderIcon, LockIcon, TimeIcon } from '../../icons';

export enum AvatarsIconTypes {
  blockedUser = 'blockedUser',
  pendingInvite = 'pendingInvite',
  folder = 'folder',
  archived = 'archived',
  none = 'none',
  error = 'error',
}

export const getLetters = (fname?: string, lname?: string, email?: string) => {
  const getFirstChar = (str: string) => {
    if (/[a-zA-Zа-яёА-ЯЁ]/.test(str.charAt(0))) {
      return str.charAt(0).toUpperCase(); // Применяем toUpperCase только к латинским и русским буквам
    }

    return str.charAt(0); // Для остальных символов возвращаем как есть
  };

  if (fname && lname) return `${getFirstChar(fname)}${getFirstChar(lname)}`;
  if (fname) return getFirstChar(fname);
  if (lname) return getFirstChar(lname);
  if (email) return getFirstChar(email);
};

export const getConvertStr = (str: string) => {
  return str
    .replace(/[^\p{L}\p{N} ]/gu, '') // оставляем только буквы и цифры из всех алфавитов, включая арабский, японский и т.д. p{L} — любая буква любого алфавита. p{N} — любая цифра
    .replace(/\s+/g, ' ') // убираем лишние пробелы
    .trim();
};

export const getConvertName = (str: string, email?: string) => {
  const strArr = str.split(' ');
  const name = getConvertStr(str).split(' ');
  return getConvertStr(str) ? getLetters(name[0], name[1], email) : getLetters(strArr[0], strArr[1] || str[1], email);
};

// внутри аватарки могут быть иконки, а не буквы
export const getIcon = (iconType: AvatarsIconTypes, size?: 20 | 24 | 40) => {
  const iconSize = size === 40 ? 20 : 16;

  // иконка крестика а-ля "проход запрещён"
  if (iconType === AvatarsIconTypes.none) return <CloseIcon color="blocked" size={iconSize} />;

  // иконка с папкой для создания нового проекта/команды
  if (iconType === AvatarsIconTypes.folder) return <FolderIcon color="white" size={iconSize} />;

  // архивная команда или проект: иконка зазипованной папки
  if (iconType === AvatarsIconTypes.archived) return <ArchiveIcon color="blocked" size={iconSize} />;

  // иконка с замочком у заблокированного пользователя
  if (iconType === AvatarsIconTypes.blockedUser) return <LockIcon color="blocked" size={iconSize} />;

  // иконка с циферблатом для пользователей, которые пока не приняли приглашение в воркспейс
  if (iconType === AvatarsIconTypes.pendingInvite) return <TimeIcon color="blocked" size={20} />;

  // иконка красного крестика для ошибки в компоненте WsAvatar
  if (iconType === AvatarsIconTypes.error) return <CloseIcon color="status_error" size={iconSize} />;
};
type TAvatarTextColor = 'FFFFFF' | '2b313b' | '626f84';

export const getTextColorForAvatar = (hexBG: string): TAvatarTextColor => {
  // если цвет фона аватара такой, то текст внутри аватарки должен быть белого цвета
  const bgColorsForWhiteText = [
    'FEAFD5',
    'EE5D80',
    'ED6CCD',
    'EEAEE8',
    'CE5DE2',
    'BEB0FA',
    '845CF7',
    'ACB4F6',
    '5671FF',
    'AACCFF',
    '46A1FF',
    '99DDE4',
    '17C5D1',
    '24A79F',
    'A1DFA8',
    '3DC453',
    'D6E994',
    'D6E994',
    'BEDA13',
    'FFDBA9',
    'FFC040',
    'FDC3AA',
    'FC8D46',
    'F3ABAB',
    'EA4B4A',
  ];

  const normalizedHexBG = hexBG.toLocaleUpperCase();

  if (normalizedHexBG === 'C4CAD4' || normalizedHexBG === 'D3D8DE') {
    return '626f84';
  }

  return bgColorsForWhiteText.includes(normalizedHexBG) ? '2b313b' : 'FFFFFF';
};
