import React from 'react';
import { Avatar } from './avatar';
import { AvatarsIconTypes } from './utils';

export enum WsItemType {
  team = 'team',
  project = 'project',
  member = 'member',
}

type TWsAvatar = {
  data: any;
  size?: 20 | 24 | 40;
  type: WsItemType; // TODO: перепроверить, мб везде получится указывать тип
  hasBorder?: boolean;
  forceGray?: boolean;
};

// у неактивной аватарки такой цвет фона
const grayBgColor = 'd3d8de';

// Компонент для рендеринга аватарки Команд, Проектов и Пользователей. Тут будем определять необходимость замены букв на иконку и необходимость обесцвеченивания аватарки.
// компонент желательно применять только к полноценному объекту data, который содержит в себе всю информацию о статусах, наличии инвайтов, наличии архивного флага и т.д. Иначе компонент не сможет вернуть неактивную серую аватарку, а будет всегда возвращать активную цветную аватарку.
// Дока: https://wiki.yandex.ru/plarindev/bazaznanijjrazra/frontend/avatarki-obevtov-vorkspejjsa.-polzovateli-komandy-/
export const WsAvatar = ({
  data,
  size,
  type,
  hasBorder,
  forceGray, // нужен, чтобы делать аватарку чб в архивных строчках таблицы
}: TWsAvatar) => {

  if (type === WsItemType.member) {
    const getIconType = () => {
      // в этот компонент пришёл "сокращённый" объект пользователя, в котором нет информации о статусе и роли в воркспейсе
      const notEnoughtData = !data.role;

      // приглашённый пользователь не принял инвайт: иконка циферблата
      if (!data.status && !notEnoughtData) return AvatarsIconTypes.pendingInvite;
      // пользователь заблокирован: иконка замка
      if (data.status === 'blocked') return AvatarsIconTypes.blockedUser;
    };

    const iconType = getIconType();

    return (
      <Avatar
        size={size}
        fname={data.fname}
        lname={data.lname}
        email={data.status ? data.email : undefined}
        name={data.name}
        shape="circle"
        backgroundColor={iconType || forceGray ? grayBgColor : data.avatar_color}
        avatarIconType={iconType}
        hasBorder={hasBorder}
      />
    );
  }

  if (type === WsItemType.team) {
    const getIconType = () => {
      // команда архивная: иконка папки
      if (data.status === 'inactive') return AvatarsIconTypes.archived;
    };

    const iconType = getIconType();

    return (
      <Avatar
        size={size}
        name={data.name}
        shape="square"
        backgroundColor={iconType || forceGray ? grayBgColor : data.avatar_color}
        avatarIconType={iconType}
        hasBorder={hasBorder}
      />
    );
  }

  if (type === WsItemType.project) {
    const getIconType = () => {
      // проект архивный: иконка папки
      if (data.is_archived === true) return AvatarsIconTypes.archived;
    };

    const iconType = getIconType();

    return (
      <Avatar
        size={size}
        name={data.name}
        shape="square"
        backgroundColor={iconType || forceGray || data.status === 'completed' ? grayBgColor : data.avatar_color} // status === 'completed' значит, что проект завершённый, и фон у аватарки будет серого цвета, но буквы не заменяются на иконку
        avatarIconType={iconType}
        hasBorder={hasBorder}
      />
    );
  }

  // Серая аватарка с красным крестом
  // В идеале, мы никогда не должны возвращать такую аватарку. Она указывает, что в компонент пришли плохие пропсы.
  return <Avatar avatarIconType={AvatarsIconTypes.error} backgroundColor={grayBgColor} />;
};
